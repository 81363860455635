import axios from 'axios';
import {toast} from 'react-toastify';
import {CartItemPizza, fetchLoginUserT, fetchSetOrderT, fetchSetUserT, RenderPizzaOrders, SetUserT} from "../../types";
import store from "../store";
import {setPizzas} from "./pizzas";


const API = process.env.REACT_APP_API


let config = {
    // @ts-ignore
    headers: { Authorization: `Bearer ${store.getState()?.cart?.user?.token || ''}` }
};

export const addPizza = (pizzaObj: CartItemPizza) => ({
    type: 'ADD_PIZZA_CART',
    payload: pizzaObj,
});

export const clearCart = () => ({
    type: 'CLEAR_CART',
});

export const setCurrency = (payload: string) => (
    {
        type: 'SET_CURRENCY',
        payload
    });


export const removeCartItem = (id: number) => ({
    type: 'REMOVE_CART_ITEM',
    payload: id,
});

export const plusCartItem = (id: number) => ({
    type: 'PLUS_CART_ITEM',
    payload: id,
});

export const minusCartItem = (id: number) => ({
    type: 'MINUS_CART_ITEM',
    payload: id,
});

export const fetchLoginUser = (userData: fetchLoginUserT, callbackOk: any, callbackNotOk:any) => async (dispatch: any) => {

    try {
        const response = await axios.post(API + '/login', userData);
        await response;

        // @ts-ignore
        dispatch(setUser({name : response.data.user.name || 'guest', email: userData.email, token: response.data.token}))
        config.headers.Authorization = `Bearer ${response.data.token || ''}`
        callbackOk()
        toast.success("Вы успешно вошли в аккаунт");
    } catch (err) {
        if(err?.response?.status == 401){
            // @ts-ignore
            dispatch(setPizzas([]))
            dispatch(unsetUser())
            callbackNotOk()
        }


        toast.error("Что-то пошло не так с входом в аккаунтом");
    }


}

export const fetchSetUser = (userData: fetchSetUserT, callbackOk: any, callbackNotOk:any) => async (dispatch: any) => {

    try {
        const response = await axios.post(API + '/register', userData);
        await response;
        dispatch(fetchLoginUser({name: userData.name, email: userData.email, password: userData.password},callbackOk,callbackNotOk))
        toast.success("Вы успешно зарегистированы");
    } catch (err) {

        toast.error("Что-то пошло не так с вашей регистрацией");
    }
}

export const fetchSetOrder = (userData: {
    customer_brand: string;
    total_price: string;
    customer_phone: string;
    cart: string;
    customer_entity: string;
    customer_site: string;
    customer_index: string;
    customer_email: string;
    name: string;
    currency: string;
    customer_name: string;
    email: string;
    customer_city: string
},callbackOk: any, callbackNotOk:any) => async (dispatch:any) => {


    try {
        const response = await axios.post(API + '/custom-order-make', userData, config);
        await response;
        toast.success("Ваш заказ создан");
        dispatch(clearCart())
    } catch (err) {
        if(err?.response?.status == 401){
            // @ts-ignore
            dispatch(setPizzas([]))
            dispatch(unsetUser())
            callbackNotOk()
        }


        toast.error("Что-то пошло не так с созданием заказа");
    }


}
export const fetchShowOrders = (userData: string,callbackOk: any, callbackNotOk:any) => async (dispatch: any) => {

    try {
        const response = await axios.get(API + '/custom-orders/' + (userData || 'tws@gmail.com'), config);
        let data = await response;

        dispatch(setOrders(data.data.data));

    } catch (err) {
        if(err?.response?.status == 401){
            // @ts-ignore
            dispatch(setPizzas([]))
            dispatch(unsetUser())
            callbackNotOk()
        }

        toast.error("Что-то пошло не так с загрузкой ваших заказов");
        // here I'd like to send the error to the user instead
    }


}


export const fetchUnsetUser =  (callbackOk: any, callbackNotOk:any) => async (dispatch: any)=> {


    try {
        const response =  axios.post(API + '/logout','', config);
        let data =  response;
        toast.success("Вы успешно вышли из аккаунта");

        dispatch(unsetUser());
        callbackOk()

    } catch (err) {
        if(err?.response?.status == 401){
            // @ts-ignore
            dispatch(setPizzas([]))
            dispatch(unsetUser())
            callbackNotOk()
        }

        toast.error("Что-то пошло не так с выходом из аккаунта");
        // here I'd like to send the error to the user instead
    }



};

export const setUser = (payload: SetUserT) => ({
    type: 'SET_USER',
    payload
});


export const unsetUser =  () => ({

    type: 'UNSET_USER'

});
export const setOrders = (payload: RenderPizzaOrders) => ({
    type: 'SET_ORDERS',
    payload
});

