import React, {useEffect} from 'react';

// @ts-ignore
import Currency from 'react-currency-formatter';
import {RenderPizza} from "../../types";
import {removeCartItem} from "../../redux/actions/cart";


export const Item: React.FC<RenderPizza> = (props) => {


    useEffect(() => {
        // code to run on component mount
        if (props.last.index === props.last.ItemsArrayLenght) {
            // @ts-ignore
            function preloadImages(sources, callback) {
                let counter = 0;

                function onLoad() {
                    counter++;
                    if (counter === sources.length) callback();
                }

                for (let source of sources) {
                    let img = document.createElement('img');
                    img.onload = img.onerror = onLoad;
                    img.src = source;
                }
            }

            let sources = [
                props.data.imageUrl
            ];

            preloadImages(sources, props.last.handleLastItem);


        }

    }, [])

    const onAddPizza = () => {
        props.onAddToCart(props.data)
    }

    const handlePlusItem = () => {
        props.onPlus(props.data.id);
    };

    const handleMinusItem = () => {
        props.onMinus(props.data.id);
    };
    const handleRemoveClick = () => {
        props.onRemove(props.data.id);
    };


    return (

        <div className='col-12 col-lg-3'>

            <div className="card">
                <div className="card-body justify-content-center ">
                    <img className="d-flex img-catalog" src={props.data.imageUrl} alt=""/>
                    <div className="card-title  ">{props.data.name}</div>
                    <p className="card-text  ">{props.data.code}</p>
                    <p className="card-text  ">{props.data.design}</p>
                    <div className="flex-row d-flex justify-content-between align-items-center">
                        <div className="d-flex price">

                            <Currency
                                quantity={props.data.price[props.currency]}
                                currency={props.currency}
                            />
                        </div>

                        {props.count > 0 &&
                        <div style={{marginRight:"20px" }} className="flex-row d-flex   align-items-center ">
                            <div className="row btn-light bor-rad p-2">
                                <div
                                    onClick={props.count >= 2 ? handleMinusItem :  handleRemoveClick }
                                    className="btn pr-2 ">
                                    -
                                </div>
                                <div className="btn">{props.count}</div>
                                <div
                                    onClick={handlePlusItem}
                                    className="btn pl-2">
                                    +
                                </div>
                            </div>
                        </div>
                        }
                        {props.count == 0 &&
                        <div className="d-flex">

                            <button type="button" className="btn button btn-primary btn-lg" onClick={onAddPizza}>Добавить</button>


                        </div>
                    }
                    </div>
                </div>
            </div>
        </div>


    );
}


