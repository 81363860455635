import React from 'react';
import {useSelector, useDispatch, RootStateOrAny} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
// @ts-ignore
import {CSSTransitionGroup} from 'react-transition-group'
import {CartItem} from './cartItem'
import {
    clearCart,
    removeCartItem,
    plusCartItem,
    minusCartItem,
    fetchSetOrder
} from '../../redux/actions/cart';
// @ts-ignore
import Currency from 'react-currency-formatter';
// @ts-ignore
import {Formik, Field, Form} from "formik";
import {CartItemPizza} from "../../types";

function validate(value: any) {
    let error;
    if (!value) {
        error = "Required";
    }
    return error;
}

export const Cart: React.FC = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const {totalPrice, totalCount, items, currency, delivery, user} = useSelector(({cart}: RootStateOrAny) => cart);

    const addedPizzas = Object.keys(items).map((key) => {
        return items[key].items[0];
    });

    const onClearCart = () => {

        dispatch(clearCart());

    };


    const onRemoveItem = (id: number) => {

        dispatch(removeCartItem(id));

    };

    const onPlusItem = (id: number) => {
        dispatch(plusCartItem(id));
    };

    const onMinusItem = (id: number) => {
        dispatch(minusCartItem(id));
    };

    return (
        <CSSTransitionGroup
            transitionName="Transition"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}>

            {user.email ?
                <div className="container  mt-12">
                    {totalCount ? (
                        <div className="cart">
                            <div className="cart__top flex-row d-flex justify-content-between mb-4">
                                <div className="d-flex align-items-center">
                                    <img alt="" className="mr-2"
                                         src="https://img.icons8.com/ios-filled/24/000000/shopping-cart.png"/>
                                    Корзина
                                </div>
                                <div className="d-flex align-items-center pointer">
                                    <svg
                                        className="mr-2"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2.5 5H4.16667H17.5"
                                            stroke="#B6B6B6"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M6.66663 5.00001V3.33334C6.66663 2.89131 6.84222 2.46739 7.15478 2.15483C7.46734 1.84227 7.89127 1.66667 8.33329 1.66667H11.6666C12.1087 1.66667 12.5326 1.84227 12.8451 2.15483C13.1577 2.46739 13.3333 2.89131 13.3333 3.33334V5.00001M15.8333 5.00001V16.6667C15.8333 17.1087 15.6577 17.5326 15.3451 17.8452C15.0326 18.1577 14.6087 18.3333 14.1666 18.3333H5.83329C5.39127 18.3333 4.96734 18.1577 4.65478 17.8452C4.34222 17.5326 4.16663 17.1087 4.16663 16.6667V5.00001H15.8333Z"
                                            stroke="#B6B6B6"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M8.33337 9.16667V14.1667"
                                            stroke="#B6B6B6"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M11.6666 9.16667V14.1667"
                                            stroke="#B6B6B6"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>

                                    <span onClick={onClearCart}>Очистить корзину</span>
                                </div>
                            </div>
                            <div className="row">
                                {addedPizzas.map((obj: CartItemPizza) => (

                                    <CartItem key={obj.id} data={obj} currency={currency}
                                              totalPrice={items[obj.id].totalPrice}
                                              totalCount={items[obj.id].items.length} onRemove={onRemoveItem}
                                              onMinus={onMinusItem} onPlus={onPlusItem}
                                    />
                                ))}
                            </div>
                            <div className="cart__bottom">
                                <div className="cart__bottom-details d-flex flex-row justify-content-between mt-2">
              <span>
                Товаров: <b>{totalCount}</b>
              </span>
                                    <span>
                Цена: <b><Currency
                                        quantity={totalPrice}
                                        currency={currency}
                                    /></b>
              </span>
                                </div>
                                <div className="cart__bottom-details d-flex flex-row justify-content-between mt-2">
              <span>

              </span>

                                </div>
                                <div className="cart__bottom-details d-flex flex-row justify-content-between mt-2">
              <span>

              </span>

                                </div>
                                <div className="cart__bottom-buttons d-flex flex-row justify-content-between mt-4">
                                    <Link to="/" className="btn ">
                                        <svg
                                            width="8"
                                            height="14"
                                            viewBox="0 0 8 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="mr-2"
                                        >
                                            <path
                                                d="M7 13L1 6.93015L6.86175 1"
                                                stroke="#D3D3D3"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>

                                        <button className="btn btn-primary bor-rad">Назад</button>

                                    </Link>
                                    <div>
                                        <Formik
                                            initialValues={{
                                                customer_brand: "-",
                                                customer_entity: "-",
                                                customer_name: "-",
                                                customer_phone: "-",
                                                customer_email: "-",
                                                customer_city: "-",
                                                customer_index: "-",
                                                customer_site: "-",
                                            }}
                                            onSubmit={async (values) => {
                                                let data = {
                                                    name: "guest",
                                                    email: "guest",
                                                    total_price: "",
                                                    currency: "",
                                                    customer_brand: "",
                                                    customer_entity: "",
                                                    customer_name: "",
                                                    customer_phone: "",
                                                    customer_email: "",
                                                    customer_city: "",
                                                    customer_index: "",
                                                    customer_site: "",
                                                    cart: ''
                                                }

                                                if (user.email) {

                                                    data.name = user.name
                                                    data.email = user.email
                                                }
                                                data.customer_brand = values.customer_brand
                                                data.customer_entity = values.customer_entity
                                                data.customer_name = values.customer_name
                                                data.customer_phone = values.customer_phone
                                                data.customer_email = values.customer_email
                                                data.customer_city = values.customer_city
                                                data.customer_index = values.customer_index
                                                data.customer_site = values.customer_site
                                                data.total_price = totalPrice
                                                data.currency = currency

                                                let itemsNew: any = []
                                                let itemsStr: any = ''


                                                for (const [key, value] of Object.entries(items)) {

                                                    let objItem = {
                                                        name: "",
                                                        code: "",
                                                        design: "",

                                                        count: "",
                                                        price: ""
                                                    }
                                                    // @ts-ignore
                                                    objItem.price = value.totalPrice
                                                    // @ts-ignore
                                                    objItem.count = value.items.length
                                                    // @ts-ignore
                                                    objItem.name = value.items[0].name
                                                    // @ts-ignore
                                                    objItem.code = value.items[0].code
                                                    // @ts-ignore
                                                    objItem.design = value.items[0].design

                                                    itemsNew.push(objItem)
                                                }

                                                itemsNew.forEach((x: any, index: number) => {
                                                    index++
                                                    if (index == 1) {
                                                        itemsStr += "\n"
                                                    }
                                                    itemsStr += (('Product number: ' + index) || '') + "\n" + ' '
                                                    itemsStr += (('Name: ' + x.name) || '') + "\n" + ' '
                                                    itemsStr += (('Code: ' + x.code) || '') + "\n" + ' '
                                                    itemsStr += (('Design: ' + x.design) || '') + "\n" + ' '
                                                    itemsStr += (('Count: ' + x.count) || '') + "\n" + ' '
                                                    itemsStr += (('Price: ' + x.price.toFixed(2) + '€') || '') + "\n\n" + ' '
                                                })

                                                // data.cart = itemsStr
                                                data.cart = JSON.stringify(itemsNew)

                                                dispatch(fetchSetOrder(data,() => {
                                                    history.push("/auth/signin")
                                                },() => {
                                                    history.push("/auth/signin")
                                                }))
                                                history.push("/")
                                            }}
                                        >

                                            { //@ts-ignore
                                                ({isSubmitting, errors, touched, isValidating}) => (
                                                    <Form className="form-group d-flex flex-column">
                                                        <label htmlFor="customer_brand">Название бренда</label>
                                                        <Field name="customer_brand" placeholder="Jonh"/>
                                                        {errors.customer_brand && touched.customer_brand && (
                                                            <div className="red">{errors.customer_brand}</div>
                                                        )}

                                                        <label htmlFor="customer_entity">Юридическое лицо</label>
                                                        <Field name="customer_entity" placeholder="Jonh"/>
                                                        {errors.customer_entity && touched.customer_entity && (
                                                            <div className="red">{errors.customer_entity}</div>
                                                        )}
                                                        <label htmlFor="customer_name">Контактное лицо </label>
                                                        <Field name="customer_name" placeholder="Jonh"/>
                                                        {errors.customer_name && touched.customer_name && (
                                                            <div className="red">{errors.customer_name}</div>
                                                        )}
                                                        <label htmlFor="customer_phone" className="mt-2">Контактный
                                                            номер</label>
                                                        <Field name="customer_phone" placeholder="+569"/>
                                                        {errors.customer_phone && touched.customer_phone && (
                                                            <div className="red">{errors.customer_phone}</div>
                                                        )}

                                                        <label htmlFor="customer_email" className="mt-2">E-mail</label>
                                                        <Field name="customer_email" placeholder="+569"/>
                                                        {errors.customer_email && touched.customer_email && (
                                                            <div className="red">{errors.customer_email}</div>
                                                        )}

                                                        <label htmlFor="customer_city" className="mt-2">Город</label>
                                                        <Field name="customer_city" placeholder="Piccadilly Circus"/>
                                                        {errors.customer_city && touched.customer_city && (
                                                            <div className="red">{errors.customer_city}</div>
                                                        )}

                                                        <label htmlFor="customer_index" className="mt-2">Адрес с
                                                            индексом</label>
                                                        <Field name="customer_index" placeholder="Piccadilly Circus"/>
                                                        {errors.customer_index && touched.customer_index && (
                                                            <div className="red">{errors.customer_index}</div>
                                                        )}

                                                        <label htmlFor="customer_site" className="mt-2">Сайт</label>
                                                        <Field name="customer_site" placeholder="Piccadilly Circus"/>
                                                        {errors.customer_site && touched.customer_site && (
                                                            <div className="red">{errors.customer_site}</div>
                                                        )}


                                                        <button type="submit"
                                                                className="btn pointer bor-rad btn-danger mt-4"
                                                                disabled={isSubmitting}>
                                                            <span>Сделать заказ</span>
                                                        </button>
                                                    </Form>
                                                )}
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="cart cart--empty container  flex-column d-flex   mt-12 text-center">
                            <h2>
                                Корзина пуста
                            </h2>

                            <Link to="/" className="button ">
                                <svg
                                    width="8"
                                    height="14"
                                    viewBox="0 0 8 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mr-2"
                                >
                                    <path
                                        d="M7 13L1 6.93015L6.86175 1"
                                        stroke="#D3D3D3"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                                <button className="btn btn-primary bor-rad">Назад</button>

                            </Link>
                        </div>
                    )}
                </div>
                : null
            }
        </CSSTransitionGroup>
    );
}


