import axios from 'axios';
import {CartItemPizza} from "../../types";
import store from '../store';
import {toast} from "react-toastify";
import {setUser, unsetUser} from "./cart";
const API = process.env.REACT_APP_API



export const fetchPizzas = (callbackOk: any, callbackNotOk:any) =>  async (dispatch:any)=> {
    // if(localStorage.getItem('state') === null) {




    try {

        let config = {
            // @ts-ignore
            headers: { Authorization: `Bearer ${store.getState()?.cart?.user?.token || ''}` }
        };
        const response = await axios.get(API + '/custom-products',config);
        let data = await response;
        let copy = JSON.parse(JSON.stringify(data.data.data))

        copy.forEach(function(item:any, i:number) {

            let priceMass = item.price.split(';')
            item.price = {}
            priceMass.forEach(function(itemPrice:any, i:number) {

                const sepatePrice = itemPrice.split(':')
                let currency = sepatePrice[0]
                if(currency === 'EUR'){
                    item.price.EUR =  parseFloat(sepatePrice[1])
                }
                if(currency === 'USD'){
                    item.price.USD= parseFloat(sepatePrice[1])
                }
            });


        });

        dispatch(setPizzas(copy))
        // toast.success("Товары успешно загружены");

    } catch (err) {

        if(err?.response?.status == 401){
            // @ts-ignore
            dispatch(setPizzas([]))
            dispatch(unsetUser())
            callbackNotOk()
        }

        toast.error("Что-то пошло не так с загрузкой товаров");
        // here I'd like to send the error to the user instead
    }



// }
}

export const lastPizza = () => (dispatch:any)=> {
        dispatch({
                type: 'SET_DOWNLOAD_PIZZAS'
        })
}

export const setPizzas = (items:CartItemPizza) => (
    {
        type: 'SET_PIZZAS',
        payload: items
    });

export const setFind = (text:string) => (
    {
        type: 'SET_FIND',
        payload: text
    });
